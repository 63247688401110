export enum ApplicationType {
  rise = 'Rise',
  storyline = 'Storyline'
}

export enum IssueType {
  feature = 'Feature Request',
  issue = 'Bug'
}

export type IssueRequestCreate = {
  application?: string
  type: IssueType
  userName: string
  email: string
  summary: string
  description: string
  product: string
  attachments?: string[]
  version?: string
}

export type ProductBoardCreateNoteRequest = {
  content: string
  source?: {
    origin: string
    record_id: string
  }
  tags: string[]
  title: string
  user?: {
    email: string
    external_id?: string
  }
}
